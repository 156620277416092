import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  SET_AS_AUTHENTICATED,
  CLEAN_REGISTRATION_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_FORGOT_FLOW,
  SET_PROFILE_ITEMS,
  USER_DATA,
  OTP,
  RESTART_OTP,
  SETTINGS,
  HOME_DATA,
  CATEGORY_DATA,
  DOCTOR_DATA,
  OPEN_REC_MODAL,
  CLOSE_REC_MODAL,
  OPEN_SEARCH_MODAL,
  cLOSE_SEARCH_MODAL,
  REMOVE_REC,
  ADD_REC,
  THANK_REC_MODAL,
  SAVE_PROF,
  SAVE_PROF_LOADER
} from "../actions/types";

const initialState = {
  user: undefined,
  AuthToken: undefined,
  refreshToken: undefined,
  isAuthenticated: false,
  forgotPasswordSuccess: false,
  registerSuccess: false,
  Name: undefined,
  otp: false,
  prof_affiliate: null,
  settings: null,
  total: {
    friends: 0,
    colleagues: 0,
    likeMe: 0,
  },
  categories: [],  
  categoryData: null,
  doctors: null,
  doctor_data: null,
  rec_modal : false,
  search_modal: false,
  thank_rec_modal: '',
  save_doctor_text: '',
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_AS_AUTHENTICATED:
      localStorage.setItem("AuthToken", payload);
      return {
        ...state,
        AuthToken: payload, // puts token in state
        isAuthenticated: true,
      };
    case SET_PROFILE_ITEMS:
      return {
        ...state,
        FirstName: payload.FirstName,
        LastName: payload.LastName,
      };
    case OTP:
      return {
        ...state,
        otp: true,
      };
    case RESTART_OTP:
      return {
        ...state,
        otp: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("AuthToken", payload.access_token);
      localStorage.setItem("FirstName", payload.FirstName);
      localStorage.setItem("LastName", payload.LastName);
      return {
        ...state,
        AuthToken: payload.access_token, // puts token in state
        isAuthenticated: true,
        FirstName: payload.FirstName,
        LastName: payload.LastName,
        user: payload,
      };
    case LOGIN_FAIL:
      return {
        ...state, 
        otp: 'fresh'
      }
    case REGISTER_FAIL:
    case FORGOT_PASSWORD_FAIL:   
    case LOGOUT:
      localStorage.removeItem("AuthToken");
      localStorage.removeItem("Name");
      return {
        ...state,
        AuthToken: null,
        isAuthenticated: false,
        user: null,
      };
    case FORGOT_PASSWORD_SUCCESS:
      localStorage.removeItem("AuthToken");
      return {
        ...state,
        AuthToken: null,
        isAuthenticated: false,
        forgotPasswordSuccess: true,
        user: null,
      };
    case RESET_FORGOT_FLOW:
      return {
        ...state,
        forgotPasswordSuccess: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registerSuccess: true,
      };
    case CLEAN_REGISTRATION_SUCCESS:
      return {
        ...state,
        registerSuccess: false,
      };
    case HOME_DATA:
      return {
        ...state,
        categories: payload.data,
        total: {
          friends: payload.data.reduce((total, cat) => total + Number(cat.recCount.friends), 0),
          colleagues: payload.data.reduce((total, cat) => total + Number(cat.recCount.colleagues), 0),
          likeMe: payload.data.reduce((total, cat) => total + Number(cat.recCount.likeMe), 0,),
        }
      }
    case CATEGORY_DATA:
   
      return {
        ...state,
        categoryData: payload.data.category,
        doctors: payload.add ? [...state.doctors, ...payload.data.professionals] : payload.data.professionals
      }
    case DOCTOR_DATA:
      return {
        ...state,
        doctor_data: payload.data
      }
    case REMOVE_REC:
      state.doctor_data.recommendations.all = state.doctor_data.recommendations.all.filter(r => r.id != payload.id)
      state.doctor_data.professional.isLike = false
      state.doctor_data.professional.myRecommendation = null;

      return {
        ...state, 
        doctors: payload.professional_id 
          ? state.doctors.map(doctor => doctor.id == payload.professional_id ? {...doctor, isLike: true} : doctor)
          : state.doctors,     
        rec_modal: false
      }
    case ADD_REC:    
   
     if(state.doctor_data.recommendations.all.find(r => r.id == payload.data.rec.id)) {
        state.doctor_data.recommendations.all = state.doctor_data.recommendations.all.map(r => r.id == payload.data.rec.id ? {...r , rec_description: payload.data.rec.rec_description} : r)
     } else {
        state.doctor_data.recommendations.all.push({...payload.data.rec, member: state.user})
     }

     state.thank_rec_modal = 'עזרת לחברים ולחברות שלך, עזרת ל' +
                            state.doctor_data.professional.first_name + ' ' + state.doctor_data.professional.last_name +
                              ' וכמובן שעזרת לעצמך. <br/>' +
                            'בחיפוש הבא נוכל להציג לך התאמות יותר רחבות ויותר מתאימות ספציפית לך';

      state.doctor_data.professional.isLike = true
      state.rec_modal = false
     return {
        ...state,
        doctors: state.doctors.map(doctor => doctor.id == payload.data.rec.professional_id ? {...doctor, isLike: true} : doctor),     
      
      }
    case USER_DATA:
      return {
        ...state,
        user: payload,
      };
    case SETTINGS:
      return {
        ...state,
        settings: payload
      }
    case OPEN_REC_MODAL:
      return {
        ...state,
          rec_modal: true,
          doctor_data: payload
      };
    case CLOSE_REC_MODAL:
      return {
        ...state,
          rec_modal: false,
      };
      case OPEN_SEARCH_MODAL:
        return {
          ...state,
            search_modal: true,
        };
      case cLOSE_SEARCH_MODAL:
        return {
          ...state,
          search_modal: false,
        };
      case THANK_REC_MODAL:
        return {
          ...state,
          thank_rec_modal: payload.data
        }
      case SAVE_PROF_LOADER:
        return {
          ...state,
          save_doctor_text: payload
        }
      case SAVE_PROF:
        if( state.doctor_data?.professional) {
          state.doctor_data.professional.isSaved = !state.doctor_data.professional.isSaved
        }

        if(state.doctors) {
          state.doctors = state.doctors.map(doc => doc.id == payload.id ? {...doc, isSaved : !doc.isSaved} : doc)
        }
      
        return {
          ...state,
          save_doctor_text: '',
        }
    default:
      return {
        ...state,
        //  AuthToken: localStorage.getItem('AuthToken'), // puts token in state
        FirstName: localStorage.getItem("FirstName"),
        LastName: localStorage.getItem("LastName"),
      };
  }
};

export default authReducer;

import React, { useEffect, useRef, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter, useIonViewDidEnter, isPlatform, IonRow, IonCol, IonSearchbar, IonGrid, IonText, IonCard, IonInfiniteScroll, IonInfiniteScrollContent, IonButtons, IonIcon, IonTextarea, IonButton, IonLoading, useIonAlert   } from '@ionic/react';
import './Recommendation.css';
import { useDispatch, useSelector } from 'react-redux';
import { closeRecModal, getHome, getMeDetails, removeRecommendation, sendContacts, sendRec, setAsAuthenticated } from '../redux/actions/authActions';

import love from "../assets/icon/love.svg"
import love_selected from "../assets/icon/love_selected.svg"
import { closeOutline } from 'ionicons/icons';

const Recommendation =  (props) => {
  
  const inputRef = useRef(null);
  const [presentAlert] = useIonAlert();

  const router = useIonRouter()
  const dispatch = useDispatch();


  
  const { user, isAuthenticated, doctor_data , rec_modal } = useSelector((state = {}) => state.auth);
  const { loading, liveActions } = useSelector((state = {}) => state.async);
  const doctor =  doctor_data ? doctor_data.professional : null;

  const [comment, setComment] = useState('');
  const [placeholder, setPlaceHolder] = useState("חברים שלך ישמחו מאוד אם תוסיף כמה מילים, אבל לא חובה :)");

  useEffect(() => {
    if(!comment) {
        setPlaceHolder("חברים שלך ישמחו מאוד אם תוסיף כמה מילים, אבל לא חובה :)")
    }
  }, [comment])

  useEffect(() => {
      setComment(doctor?.myRecommendation?.rec_description || '')    
  }, [doctor])

  const sendComment = () => {
      let params = {
        professional_id: doctor.id,
        rec_description: comment
      }

      dispatch(sendRec(params))
  }

  const removeRec = () => {
    presentAlert({
      cssClass: 'my-custom-class',
      message: 'מעוניין להסיר את המלצתך?',
      buttons: [
        {
          text: 'לא',
          cssClass: 'secondary',
          role: 'cancel',
        }, 
        {
          text: 'כן',
          handler: () => {
            setComment('')
            dispatch(removeRecommendation(doctor?.myRecommendation.id, doctor?.myRecommendation.professional_id))
          }
        }
      ]
    })
  }

const closeDialog = () => {
  dispatch(closeRecModal());
}

  
 
  return (
    <IonPage className='rec-page'>
        <IonHeader className="ion-no-border">
            <IonToolbar className="likePage">    
                <IonButtons slot="start" onClick={closeDialog}>
                    <IonIcon icon={closeOutline}></IonIcon>
                </IonButtons>
            
            </IonToolbar>
        </IonHeader>

        <IonContent className="withBg">
            <IonGrid>
                <IonRow>
                    <IonCol className="ion-text-center">
                        <img src={love_selected} width="35px" />
     
                        <div className="drName">{ doctor?.first_name } { doctor?.last_name }</div>
                        <div className="drDomain">{ doctor?.category.name }</div>
                    </ IonCol>
                </ IonRow>

                < IonRow>
                    < IonCol>
                        <IonTextarea className="recommTextarea" value={comment} onKeyUp={(e) => setComment(e.target.value)} autoGrow="true" rows="4" clearOnEdit="true"
                            placeholder={placeholder} onIonFocus={() => setPlaceHolder('')}></IonTextarea>
                        <IonButton expand="block" onClick={sendComment}>
                            המלצה
                        </IonButton>
                    </ IonCol>
                </ IonRow>

                < IonRow>
                    < IonCol className="ion-text-center"> 
                        <a className="remove" onClick={removeRec}> { doctor?.myRecommendation ? "הסרת המלצה" : ''  }</a>
                    </IonCol>
                </ IonRow>
            </IonGrid>
        </IonContent>
        {loading && <IonLoading isOpen={loading}></IonLoading>}
    </IonPage>
  );
};

export default Recommendation;

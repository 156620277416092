import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import {
  IonContent,
  IonInput,
  IonPage,
  IonButton,
  IonCol,
  IonRow,
  useIonRouter,
  useIonViewDidEnter,
  IonCheckbox,
  IonLoading,
  IonText,
  IonItem,
  IonGrid,
  IonIcon,
  IonCardHeader,
  IonCardTitle,
  IonCard,
  IonLabel,
  IonCardSubtitle,
  IonAvatar
} from '@ionic/react';
import './MyProfile.css';
import { useDispatch, useSelector } from 'react-redux';
import { getMeDetails, login, restartOtp, saveProfile, sendOtp, setAsAuthenticated } from '../redux/actions/authActions';
import logo from '../assets/imgs/logo.svg'
import { linkOutline, pencilOutline, person } from 'ionicons/icons';
import { useLocation } from 'react-router';
import ReCAPTCHA from "react-google-recaptcha";
import {Helmet} from "react-helmet";
import step1 from '../assets/imgs/step1.svg';
import Header from '../components/Header';


const MyProfile = () => {
  const router = useIonRouter()
  const location = useLocation()
  const { user, isAuthenticated, otp, prof_affiliate, settings } = useSelector((state = {}) => state.auth);
  const { loading, liveActions } = useSelector((state = {}) => state.async);

  const dispatch = useDispatch();


  useIonViewDidEnter(() => {   
    if (!localStorage.getItem('AuthToken')) {
      router.push('/login')
    }

    if (!liveActions.includes('me') && !isAuthenticated && localStorage.getItem("AuthToken")) {
      dispatch(setAsAuthenticated(localStorage.getItem('AuthToken')))
      dispatch(getMeDetails())     
    }
  });

  const fileInput = useRef();

  const share = () => {
    //
  }

  const autoSave = (key, val) => {
    dispatch(saveProfile([{
         key: key, value: val
    }]))
  }

  const loadImageFromDevice = (event) => {
  
    const file = event.target.files[0];  
   
    const reader = new FileReader();  
    reader.readAsDataURL(file);
    reader.onload = () => { 
        dispatch(saveProfile([{
            key: 'img', value: reader.result
        }]));
    };
  }

  if(location.pathname != '/my-profile') {
    return (<></>)
  }

  return (
    <IonPage id="my-profile-page">
       <Helmet>
          <title>פרופיל אישי</title>
      </Helmet>
      <Header search={false}/>
      <IonContent fullscreen>
      {user &&<div>
        <IonRow style={{"margin-top": "-20px"}}>
            <IonCol>
              <IonText className="title text-center">
                <h1>איזור אישי</h1>
                   <h3>{ user.first_name } { user.last_name }</h3>
              </IonText>
            </IonCol>
          </IonRow>
       
          <IonGrid>
      
            <IonCard className="profie-card">
                <IonCardHeader>
                  <IonCardTitle>פרטים אישיים</IonCardTitle>
                  <IonAvatar width="25px" className="avatar-dr">
                    <img src={user.img || person} />
                    <IonIcon icon={pencilOutline} id="img-icon" size="meduim" onClick={() => fileInput.current.click()}></IonIcon>
                  </IonAvatar>
              
                <input type="file" ref={fileInput} onChange={loadImageFromDevice} id="file-input" accept="image/png, image/jpeg" />
              
                </IonCardHeader>
              
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel position="floating"> שם פרטי</IonLabel>
                      <IonInput value={user.first_name} required onIonBlur={($event) => autoSave('first_name', $event.target.value)}></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol className="text-center">
                    <IonItem>
                      <IonLabel position="floating"> שם משפחה</IonLabel>
                      <IonInput value={user.last_name} required  onIonBlur={($event) => autoSave('last_name', $event.target.value)} ></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol className="text-center">
                    <IonItem>
                      <IonLabel position="floating"> נייד</IonLabel>
                      <IonInput  value={user.mobile} disabled></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>
              
              </IonCard>
              {user.professional_id && <IonCard className="profie-card">
                <IonCardHeader>
                  <IonCardTitle>פרופיל עסקי</IonCardTitle>
                  <IonCardSubtitle onClick={share} >  
                    <IonIcon icon={linkOutline} style={{'margin-top': '3px', 'padding-left': "3px"}}></IonIcon>
                    לינק לשיתוף</IonCardSubtitle>
             
                

                  <IonAvatar width="45px" className="avatar-dr">
                    <img src={user.prof_details.img_url || person} />
                    <IonIcon icon={pencilOutline} id="img-icon" size="meduim" onClick={() => fileInput.current.click()}></IonIcon>
                  </IonAvatar>
              
                <input type="file" ref={fileInput} onChange={loadImageFromDevice} id="file-input" accept="image/png, image/jpeg" />
                </IonCardHeader>
              
                
                <IonRow>
                    <IonCol className="text-center" >
                      <IonItem>
                        <IonLabel position="floating"> רחוב</IonLabel>
                        <IonInput value={user.prof_details.street} required onIonBlur={($event) => autoSave('prof_details.street', $event)}></IonInput>
                      </IonItem>
                  </IonCol>
                  <IonCol className="text-center">
                    <IonItem>
                      <IonLabel position="floating"> מ. בית</IonLabel>
                      <IonInput value={user.prof_details.house_number} required  onIonBlur={($event) => autoSave('prof_details.house_number', $event)}></IonInput>
                    </IonItem>
                  </IonCol>
               
                </IonRow>
                
                <IonRow>
                  <IonItem>
                    <IonLabel position="floating"> עיר</IonLabel>
                    <IonInput value={user.prof_details.city} required  onIonBlur={($event) => autoSave('prof_details.city', $event)}></IonInput>
                  </IonItem>
                </IonRow>
                
                <IonRow>
                    <IonItem>
                      <IonLabel position="floating"> טלפון</IonLabel>
                      <IonInput  value={user.prof_details.phone} onIonBlur={($event) => autoSave('prof_details.phone', $event)}></IonInput>
                    </IonItem>                    
                </IonRow>

                
                <IonRow>
                  <IonItem>
                    <IonLabel position="floating"> פייסבוק</IonLabel>
                    <IonInput  value={user.prof_details.facebook_url} onIonBlur={($event) => autoSave('prof_details.facebook_url', $event)}></IonInput>
                  </IonItem>    
                </IonRow>

                
                <IonRow>
                  <IonItem>
                    <IonLabel position="floating"> אתר</IonLabel>
                    <IonInput  value={user.prof_details.site_link} onIonBlur={($event) => autoSave('prof_details.site_link', $event)}></IonInput>
                  </IonItem>    
                </IonRow>
                
              </IonCard>}

            
              {user.profs_match && user.profs_match.length > 0 && <div>
                <IonRow style="margin-top: -20px;">
                    <IonCol>
                      <IonText className="title IonText-center">
                        הרופאים / בעלי המקצוע שלי
                      </IonText>
                    </IonCol>
                  </IonRow>
               
                <IonGrid>
            
              {user.profs_match.map((prof, key) =>  (
                <IonCard className="profie-card" key={key} /*(click)="toDoctor(prof.id)"*/>
                <IonRow className="ion-justify-content-start">
                  <IonCol size="2">
                    <IonAvatar width="25px">
                      <img src={prof.img_url || person}/>
                    </IonAvatar>

                  </IonCol>
                  <IonCol>
                    <span className="DrName">{ prof.first_name } { prof.last_name }</span>
                    <span className="DrDomain">{ prof.category?.name }</span>
                    </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <span className="DrAddress">
                      <IonIcon src="../../assets/icon/location.svg"></IonIcon>
                      { prof.street } 
                      {prof.house_number && <span> { prof.house_number },</span>}  { prof.city }
                    </span>
                   <br />
                    {prof.phone && <span className="DrPhone" >
                      <IonIcon src="../../assets/icon/phone.svg"></IonIcon>
                        <a href={"tel:"+ prof.phone  }> { prof.phone }</a>
                      <br />
                    </span>}
                    {prof.mobile && <span className="DrPhone">
                      <IonIcon src="../../assets/icon/phone.svg"></IonIcon>
                        <a href={"tel:"+ prof.mobile  }> { prof.mobile }</a>
                      <br />
                    </span>}
                  </IonCol>
              </IonRow>
                
              </IonCard>))}
              </IonGrid>
              </div>}

          </IonGrid>
    </div>}
      </IonContent>
    </IonPage>
  );
};

export default MyProfile;

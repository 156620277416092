import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter, useIonViewDidEnter, isPlatform, IonRow, IonCol, IonSearchbar, IonGrid, IonText, IonCard, IonInfiniteScroll, IonInfiniteScrollContent, IonModal   } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Home.css';
import { useDispatch, useSelector } from 'react-redux';
import { getHome, getMeDetails, presentSearchModal, sendContacts, setAsAuthenticated } from '../redux/actions/authActions';
import { Contacts } from '@capacitor-community/contacts';

import step1 from '../assets/imgs/step1.svg'
import Header from '../components/Header';
import SearchPage from './SearchPage';

const Home = () => {

  const router = useIonRouter()
  const dispatch = useDispatch();
  
  const { user, isAuthenticated, categories, total, search_modal } = useSelector((state = {}) => state.auth);
  const { loading, liveActions } = useSelector((state = {}) => state.async);

  const [page, setPage] = useState(1);

  useIonViewDidEnter(() => {
   
    if (!localStorage.getItem('AuthToken')) {
      router.push('/login')
    }

    if (!liveActions.includes('me') && !isAuthenticated && localStorage.getItem("AuthToken")) {
      dispatch(setAsAuthenticated(localStorage.getItem('AuthToken')))
      dispatch(getMeDetails())      
    }

  });


  useEffect(() => {
    if(user && !user.phonebook && isPlatform('android')) {
      try {
      retrieveListOfContacts()
      } catch (e) {
        console.log(e)
      }
    }
  }, [user])

  useEffect(() => {
    
    if(isAuthenticated && (!categories || !categories.length) && !liveActions.includes('home')) {
      dispatch(getHome(page));
    }
    if (location.pathname != '/login' && !isAuthenticated && !localStorage.getItem('AuthToken')) {
      router.push('/login')
    }
  }, [isAuthenticated])

  const retrieveListOfContacts = async () => {
    /*if(!isPlatform('android')) {
      return;
    }
    const permission = await Contacts.checkPermissions();
    if (permission.contacts == 'denied') {
      return;
    }

    if(permission.contacts == 'prompt' || permission.contacts == 'prompt-with-rationale') {
      const perm2  = await Contacts.requestPermissions()
      if(perm2.contacts != 'granted') {
        return;
      }
    }
    
    const projection = {
      // Specify which fields should be retrieved.
      name: true,
      phones: true,
      postalAddresses: true,
    };
  
    const result = await Contacts.getContacts({
      projection,
    });
    console.log(result)
    dispatch(sendContacts(result.contacts))
    */
  };
  if(location.pathname != '/home') {
    return (<></>)
  }
  

  return (
    <IonPage>
      <Header />
      <IonContent fullscreen>
        <IonRow>
          <IonCol>
              <IonSearchbar type="text" debounce="500"  onClick={() => dispatch(presentSearchModal())}
                    placeholder="שם, טיפול, מומחיות, מרפאה…" searchIcon="search-sharp">
                </IonSearchbar>
            </IonCol>
        </IonRow>
        <IonGrid style={{"marginTop": "-20px"}}>
            <IonRow className="ion-align-items-end ion-justify-content-center ion-text-center">
                <IonCol>
                    <IonText>
                        <p>
                            <span className="recomNum">{total.friends}<br /></span>
                            <span className="recomType"> המלצות<br /> מחברים </span>
                        </p>
                    </IonText>
                </IonCol>
                <IonCol>
                    <IonText>
                        <p>
                            <span className="recomNumWhoKnows">{total.colleagues }<br /></span>
                            <span className="recomType"> המלצות <br />מקולגות </span>
                        </p>
                    </IonText>
                </IonCol>
                <IonCol>
                    <IonText>
                        <p>
                            <span className="recomNum">{total.likeMe }<br /></span>
                            <span className="recomType"> המליצו<br /> כמוני
                            </span>
                        </p>
                    </IonText>
                </IonCol>
            </IonRow>

        </IonGrid>
        <IonRow>
            <IonCol className="ion-text-center">
                <img src={step1} width="240px"/>
            </IonCol>
        </IonRow>

        <IonGrid>

            {categories.map((item, key) => ( 
            <IonCard key={key} routerLink={"/domain?id=" + item.category.id }>
                <IonRow className="ion-align-items-center ion-text-center ">
                    <IonCol size="4" className="ion-align-item-start">
                        <span className="title bold">{item.category.name}</span>
                    </IonCol>
                    <IonCol size="2" className="push">
                        <span className="domainRecomNum">{item.recCount.friends}</span><br />
                        <span className="domainRecom">חברים</span>
                    </IonCol>
                    <IonCol size="2" className="">

                        <span className="domainRecomNum">{item.recCount.colleagues}</span><br />
                        <span className="domainRecom">קולגות</span>
                    </IonCol>
                    <IonCol size="2" className="">

                        <span className="domainRecomNum">{item.recCount.likeMe}</span><br />
                        <span className="domainRecom">כמוני</span>
                    </IonCol>
                    <IonCol size="2" className="">

                        <span className="domainRecomNum">{item.recCount.all > 1000 ? (`+${(item.recCount.all / 1000).toFixed(0)}K`)  : item.recCount.all}</span><br />
                        <span className="domainRecom">סך הכל</span>
                    </IonCol>
                </IonRow>
            </IonCard>)
            )}

        </IonGrid>

        {/* <IonInfiniteScroll  threshold="100px">
        <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText=""></IonInfiniteScrollContent>
            </IonInfiniteScroll>*/}
          

      </IonContent>

      { search_modal && <IonModal isOpen={search_modal}>
        <SearchPage />
    </IonModal>}
    </IonPage>
  );
};

export default Home;

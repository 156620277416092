import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonLabel, IonTabButton, IonIcon, IonTabBar, IonRouterOutlet, IonTabs, createAnimation, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

import { Provider, useDispatch } from 'react-redux'; 
import store from './redux/store/store'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Login from './pages/Login';
import Domain from './pages/Domain';
import DoctorDetails from './pages/DoctorDetails';
import { home, logOut, person } from 'ionicons/icons';
import { logout } from './redux/actions/authActions';
import MyProfile from './pages/MyProfile';

setupIonicReact();


const animationBuilder = (baseEl : any, opts: any) => {

  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo(
      'transform',
      'translateX(100%)',
  
      'translateX(0)'
    )
   // .direction('reverse')
  .duration(250);
  
  const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo(
      'transform',
      'translateX(100%)',
  
      'translateX(0)'
    )
    .direction('reverse')
  .duration(350);
  
  const animation = createAnimation()
    .addAnimation(enteringAnimation)
    .addAnimation(leavingAnimation);
    
  return animation;
};

const Content  = () => {
  
const dispatch = useDispatch();

const logoutFunc = (e: any) => {
  e.preventDefault();
  dispatch(logout())
}
  return (
<IonApp>
 
 <IonReactRouter>
 <IonTabs>
   <IonRouterOutlet animation={animationBuilder}>
     <Route exact path="/home">
       <Home />
     </Route>
     <Route exact path="/login">
       <Login />
     </Route>
     <Route exact path="/domain">
       <Domain />
     </Route>
     <Route exact path="/doctor-details">
       <DoctorDetails />
     </Route>
     <Route exact path="/my-profile">
       <MyProfile />
     </Route>
     <Route exact path="/">
       <Redirect to="/home" />
     </Route>

     </IonRouterOutlet>

     <IonTabBar slot="bottom">
       <IonTabButton tab="home" href="/home">
         <IonIcon icon={home} />
         <IonLabel>ראשי</IonLabel>
       </IonTabButton>

     

       <IonTabButton tab="my-profile" href="/my-profile">
         <IonIcon icon={person} />
         <IonLabel>הפרופיל שלי</IonLabel>
       </IonTabButton>

       <IonTabButton  tab="login" href="/login" onClick={logoutFunc}>
         <IonIcon icon={logOut} />
         <IonLabel>התנתקות</IonLabel>
       </IonTabButton>
     </IonTabBar>
   
  
   </IonTabs>
 </IonReactRouter>    

</IonApp>
  )
}


const App: React.FC = () => (
  <Provider store={store}> 
    <Content />
  </Provider>
);



export default App;

import React, { useRef, useState } from 'react';
import './Header.css';
import { IonAvatar, IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonList, IonMenu, IonMenuButton, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import logo from '../assets/imgs/logo.svg'
import { arrowForwardOutline, menu, person } from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { presentSearchModal } from '../redux/actions/authActions';

const Header = ( props ) => {


    const dispatch = useDispatch();
    const search  = props.search;
    const isSearchModal = false//props?.isSearchModal || false;
    const { user } = useSelector((state = {}) => state.auth);

  return (
    <IonHeader className="header ion-no-border" >
    <IonToolbar>     
        <IonButtons slot="start" className={search ? 'backIcon': ''}>
     
            {!search && <IonAvatar>
                <img src={user?.img || person} />
            </IonAvatar>}
            {search && <IonBackButton  defaultHref="/" icon={arrowForwardOutline} text="" color="dark">
            </IonBackButton>}
        </IonButtons>
        {!search && <IonTitle routerLink={"/home"} routerDirection={"root"}>
            <div className="logo">
                <img style={{marginRight: "-25px"}} src={logo} width="110px" />
            </div>
        </IonTitle>}
        {search && <IonButtons>
            {!isSearchModal && <IonSearchbar onClick={(e) =>  {
            e.preventDefault();
            dispatch(presentSearchModal())
        }}  type="text" debounce="500" placeholder="שם, טיפול, מומחיות, מרפאה…"
                searchIcon="search-sharp" >
            </IonSearchbar>}
        </IonButtons>}
        
        
    </IonToolbar>
</IonHeader>
  );
};

export default Header;

import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter, useIonViewDidEnter, isPlatform, IonRow, IonCol, IonSearchbar, IonGrid, IonText, IonCard, IonInfiniteScroll, IonInfiniteScrollContent, IonAvatar, IonIcon, useIonViewDidLeave, useIonModal, IonModal, IonAlert, IonLoading, useIonToast   } from '@ionic/react';
import './DoctorDetails.css';
import { useDispatch, useSelector } from 'react-redux';
import { clearDoctor, getCategory, getDoctor, getHome, getMeDetails, presentRecModal, saveDoctor, sendContacts, setAsAuthenticated } from '../redux/actions/authActions';
import { useLocation } from 'react-router';
import { Share } from '@capacitor/share';

import Header from '../components/Header';
import phone from "../assets/icon/phone.svg"
import locationSvg from "../assets/icon/location.svg";
import save_selected from "../assets/icon/save_selected.svg"
import save from '../assets/icon/save.svg'
import love from "../assets/icon/love.svg"
import love_selected from "../assets/icon/love_selected.svg"
import person from "../assets/icon/person.svg"
import whatsapp from "../assets/icon/whatsapp.svg"
import waze from "../assets/icon/waze_icon_2020.svg"
import call from "../assets/icon/call.svg"
import share from "../assets/icon/share_copy_2.png"
import facebook from "../assets/icon/i-facebook.png"
import moment from 'moment'
import Recommendation from './Recommendation';
import SearchPage from './SearchPage';


const DoctorDetails = () => {

  const router = useIonRouter()
  const dispatch = useDispatch();
  const location = useLocation();


  const id = (new URLSearchParams(location.search.replace('?', ''))).get('id');
  const { user, isAuthenticated, doctor_data, rec_modal, search_modal, thank_rec_modal, save_doctor_text } = useSelector((state = {}) => state.auth);
  const { loading, liveActions } = useSelector((state = {}) => state.async);
  const [selected , setSelected] =  useState('all');
  
  const recommendationsAll = doctor_data ? doctor_data.recommendations : null;
  const recommendations = recommendationsAll ? recommendationsAll[selected] : []
  const totalLikes = recommendationsAll ? recommendationsAll.all.length : 0;
  const doctor =  doctor_data ? doctor_data.professional : null;

  const [page, setPage] = useState(1);
  const [itemSelected, setItemSelected] = useState(null);

  useIonViewDidLeave(() => {
    dispatch(clearDoctor())
  })


  useIonViewDidEnter(() => {
   
    if (!localStorage.getItem('AuthToken')) {
      router.push('/login')
    }

    if (!liveActions.includes('me') && !isAuthenticated && localStorage.getItem("AuthToken")) {
      dispatch(setAsAuthenticated(localStorage.getItem('AuthToken')))
      dispatch(getMeDetails())
    }
    /*
    if(!doctor_data || doctor_data.professional.id != id || !recommendationsAll){
        dispatch(getDoctor(id))
    } */  
  });

  useEffect(() => {
    if(location.pathname == '/doctor-details' && !liveActions.includes('doctor') && isAuthenticated) {
        if(!doctor_data || doctor_data.professional.id != id || !recommendationsAll){
            dispatch(getDoctor(id))         
        }   
    }
  },[id])

  useEffect(() => {
    if(!isAuthenticated && !localStorage.getItem("AuthToken")) {
        router.push('/login');
    }
    if(isAuthenticated) {
        if(!doctor_data || doctor_data.professional.id != id || !recommendationsAll){
            dispatch(getDoctor(id))
        }
    }
  }, [isAuthenticated])

 

  const getWAlink = (mob, tel) => {
 
    if (mob && mob.charAt(0) == 0 && mob.charAt(1) == 5) {
        return "https://wa.me/+972" + mob.slice(1);
    } 
    if (tel && tel.charAt(0) == 0 && tel.charAt(1) == 5) {
        return "https://wa.me/+972" + tel.slice(1);
    } 
    return null;      
  }

  const toDoctor = (id) => {
    router.push('/doctor-details?id=' + id)
  }

  const [present] = useIonToast();


  const shareFunc = async (e) => {
    e.preventDefault();
    const text = "הי, ראיתי " +  (doctor.category.description ? doctor.category.description : doctor.category.name) + " בשבילך \n 🧑‍🎓 " + 
    doctor.first_name +
    " " +
    doctor.last_name + '\n' +     
    (doctor.mobile ? ("🤳 טלפון: " + doctor.mobile + '\n') : '' )
    + '\n👇 לחץ כאן \n'
    + 'https://app.doctorita.co.il/doctor-details?id=' + doctor.id
    doctor.phone;
    Share.share({
        text:  text,
    })
   
    //const {isShare} = await Share.canShare();
  
   // if(!isShare) {
      /*  if(navigator?.share) {
            navigator.share({
                title: 'דוקטוריטה',
                text: text,
                url: 'app.doctorita.co.il',
              })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
            
        } else {
        present({
            message: 'ניתן לשתף רק באפליקציה',
            duration: 1500,
            position: 'bottom',
          });
        }
        return;
    }

            Share.share({
                text:  text
            })
        }*/
  }

  const toogleSave = async () => {    
    dispatch(saveDoctor(doctor.id, !doctor.isSaved, doctor.isSaved ? "מסירים לך את השמירה..." : "שומרים לך את הדוקטור..."))
  }

  const presentModal = (e) => {
    e.preventDefault()
    dispatch(presentRecModal(doctor_data))
  }

  if(location.pathname != '/doctor-details') {
    return (<></>)
  }

  return (
    <IonPage>
     <Header search={true} />
  
      <IonContent fullscreen>
      {doctor && 
        <div className="doctor">
        <IonGrid>
            <IonCard className="doctorCard">
                <IonRow className="ion-justify-content-start">
                    <IonCol size="2">
                        <IonAvatar width="25px">
                            <img src={doctor.img_url || person} />
                        </IonAvatar>
                    </IonCol>
                    <IonCol>
                        <span className="DrName">{ doctor.first_name + ' ' + doctor.last_name }</span>
                        <span className="DrDomain">{ doctor.category.name }</span>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="DrTags">
                        {doctor.expertises.map((expertise, key) => (
                        <span className="" key={key} >
                            { expertise.name }
                        </span>))}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        {(doctor.street || doctor.house_number || doctor.city) && 
                        <IonRow className="DrAddress">
                            <IonIcon src={locationSvg}></IonIcon>
                            <span>
                                { (doctor.street ? (doctor.street + ' ') : '') 
                                +  (doctor.house_number ? (doctor.house_number +', ') : ' ') + (doctor.city || '') }
                            </span>   
                    </IonRow>}
                    {doctor.phone && <IonRow  className="DrPhone">
                            <IonIcon src={phone}></IonIcon>
                            <a href={"tel:"+ doctor.phone }>{ doctor.phone }</a>
                    </IonRow>}
                    {doctor.mobile && 
                    <IonRow className="DrPhone">
                            <IonIcon  src={phone}></IonIcon>
                            <a href={"tel:" + doctor.mobile }>{ doctor.mobile }</a>
                    </IonRow>}
                    </IonCol>
                    <div onClick={toogleSave} >
                        {!doctor.isSaved && <img  src={save} className="imgSave" />}
                        {doctor.isSaved && <img src={save_selected} className="imgSave" />}
                    </div>
                </IonRow>

                <IonRow className="ion-align-items-center ion-justify-content-center ion-text-center iconRow">

                    <IonCol className="ion-align-self-center">
                        <div className="imgIcon imgShare">
                            <span className="helper"></span>
                            <a href='#' onClick={shareFunc} >
                                <img src={share}  />
                            </a>
                        </div>
                        <div className="iconLabel">
                            שיתוף
                        </div>
                    </IonCol>
                    {doctor.addr_lat && doctor.addr_lng && 
                    <IonCol className="ion-align-self-center">
                        <div className="imgIcon imgWaze">
                            <span className="helper"></span>
                            <a href={"geo:" + doctor.addr_lat + "," + doctor.addr_lng}>
                                <img  src={waze} />
                            </a>
                        </div>
                        <div className="iconLabel">
                            ניווט
                        </div>
                    </IonCol>}
                    {!!doctor.mobile && <IonCol className={''}>
                        <div className="imgIcon imgWA">
                            <span className="helper"></span>
                            <a href={"https://wa.me/+972" + doctor.mobile.slice(1)}>
                                <img src={whatsapp} />
                            </a>
                        </div>
                        <div className="iconLabel">
                            וואטסאפ
                        </div>
                    </IonCol>}
                    { (doctor.phone || doctor.mobile) && <IonCol>
                        <div className="imgIcon imgCall">
                            <span className="helper"></span>
                            <a href={'tel:' + (doctor.phone ? doctor.phone : doctor.mobile)}>
                                <img src={call} />
                            </a>
                        </div>
                        <div className="iconLabel">
                            להתקשר
                        </div>
                    </IonCol>}

                    {/*doctor.facebook_url && <IonCol>
                        <div className="imgIcon imgHeart">
                            <span className="helper"></span>
                            <a href={doctor.facebook_url} rel='noreferrer' target={"_blank"}>
                                <img src={facebook}/>
                            </a>
                        </div>
                        <div className="iconLabel">
                           facebook
                        </div>
                    </IonCol>*/}

                    <IonCol  onClick={presentModal}>
                        <div className="imgIcon imgHeart">
                            <span className="helper"></span>
                            <img src={doctor.isLike ? love_selected : love}  />
                        </div>
                        <div className="iconLabel">
                            המלצה
                        </div>
                    </IonCol>                    
                </IonRow>
            </IonCard>
        </IonGrid>
    </div>}

    {recommendationsAll && 
        <div className="recommendations">
        <IonGrid>
            <IonRow className="ion-align-items-center ion-justify-content-center ion-text-center">
                <IonCol className={"like " + (selected === 'all' ? 'selectedLikes' : '') } onClick={() => setSelected('all')} >
                    <div className="num"> { totalLikes }</div>
                    <div className="label">המלצות</div>
                </IonCol>
                <IonCol className={"like " + (selected === 'friends' ? 'selectedLikes' : '' )} onClick={() => setSelected('friends')} >
                    <div className="num">{ recommendationsAll.friends.length }</div>
                    <div className="label">חברים</div>
                </ IonCol>
                < IonCol className={"like " + ( selected === 'colleagues' ? 'selectedLikes' : '' )} onClick={() => setSelected('colleagues')}>
                    <div className="num">{ recommendationsAll.colleagues.length }</div>
                    <div className="label">קולגות</div>
                </ IonCol>
                <IonCol className={"like " + ( selected === 'likeMe' ? 'selectedLikes' : '' )}  onClick={() => setSelected('likeMe')}>
                    <div className="num">{ recommendationsAll.likeMe.length }</div>
                    <div className="label">כמוני</div>
                </IonCol>
            </IonRow>
        </IonGrid>
        
    </div>}

    <div className="recommDetails">
        {recommendations.map((item, key) => (
        <div className="likeComment" key={key}>
            <IonGrid>
                <IonCard>
                    <IonRow style={{'width': '100%'}}>
                        {item.member &&< IonCol size={'2'}  className="ion-align-self-start">
                            <div className="commentImg">
                                
                                <IonAvatar >
                                    <img src={item.member?.img || person} />
                                </ IonAvatar>
                               {/* <app-user-avatar [class]="'smallAvatar'" [color]="item.member.color" [fName]="item.member.first_name" [lName]="item.member.last_name || ''" *ngIf="!item.member.img"></app-user-avatar>
                            */}</div>
                        </IonCol>}
                        <IonCol size={'10'} className="comDet">
                            <div className="commentTitle">{ item.member.first_name } { item.member.last_name }</div>

                            {item.type === 'like_me' && item.professional_ids && item.professional_ids.length > 0 
                                && <div className="commentLikeMe">
                                <a onClick={() => setItemSelected(itemSelected == item.id ? null : item.id)}>
                                    המליץ כמוך על { item.professional_ids.length } רופאים
                                    <span className={ "tips " + (item.id == itemSelected ? 'disptrue': '')} id="tips">
                                        {doctor_data.otherMyRecProfessionals.filter(p => item.professional_ids.includes(Number(p.id))).map((doctor, key) => (
                                            <span onClick={() => toDoctor(doctor.id)} key={key}>
                                                { doctor.first_name +  " " +  doctor.last_name + " - " +  doctor.category.name }
                                                <br />
                                            </span>
                                        ))}
                                    </span>
                                </a>
                            </div>}
                            {item.type === 'friend' && <div className="commentFriend">חבר</div>}
                            {item.type === 'colleague' && <div className="commentYouLiked">
                                <span className="commentRed">{item.colleagueCategory}</span> שהמלצת עליו
                            </div>}

                            <div className="recommText">{ item.rec_description }</div>
                            <div className="commentDate">{ moment(item.created_at).format('DD-MM-YYYY') }</div>
                        </IonCol>
                    </IonRow>
                </IonCard>
            </IonGrid>
        </div>))}
    </div>
   
      </IonContent>
      { !!rec_modal && <IonModal isOpen={!!rec_modal}>
        <Recommendation />
    </IonModal>}
    { !!search_modal && <IonModal isOpen={!!search_modal}>
        <SearchPage />
    </IonModal>}
    {loading && <IonLoading isOpen={loading && liveActions.includes('doctor')} />}
    {!!thank_rec_modal && <IonAlert isOpen={!!thank_rec_modal} 
                subHeader={thank_rec_modal.split('<br/>')[0]}
                message={thank_rec_modal.split('<br/>')[1]} header={'איזה כיף שהמלצת!'}></IonAlert>}
    {!!save_doctor_text && <IonLoading isOpen={!!save_doctor_text} message={save_doctor_text}></IonLoading>}
    </IonPage>
  );
};

export default DoctorDetails;

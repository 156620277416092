import React, { useEffect, useRef, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonList, IonToolbar, useIonRouter, useIonViewDidEnter, IonItem, IonRow, IonCol, IonSearchbar, IonGrid, IonText, IonCard, IonInfiniteScroll, IonInfiniteScrollContent, IonAvatar, IonIcon, IonModal, IonButtons   } from '@ionic/react';
import './SearchPage.css';
import { useDispatch, useSelector } from 'react-redux';
import { closeSearchModal, getCategory, getDoctor, getHome, getMeDetails, presentRecModal, sendContacts, setAsAuthenticated } from '../redux/actions/authActions';
import { useLocation } from 'react-router';
import phone from "../assets/icon/phone.svg"
import locationSvg from "../assets/icon/location.svg";
import save_selected from "../assets/icon/save_selected.svg"
import save from '../assets/icon/save.svg'
import love from "../assets/icon/love.svg"
import love_selected from "../assets/icon/love_selected.svg"
import person from "../assets/icon/person.svg"
import Header from '../components/Header';
import Recommendation from './Recommendation';
import { arrowBackOutline, arrowForwardOutline, libraryOutline, personOutline } from 'ionicons/icons';
import algoliasearch from 'algoliasearch/lite';


const SearchPage = () => {

    const router = useIonRouter();
    const [results, setResults] = useState(null)
    const [term, setTerm] = useState('')
    const [category, setCategory] = useState('');

    const dispatch = useDispatch();
    
    const client = algoliasearch('4BVKP3AWD6', '38e6fbc9f3a3ee8fb879805acdedf91f');

    const index = client.initIndex('doctorita');

    const close = () => {
        dispatch(closeSearchModal());
    }

    const searchBar = useRef();

    useIonViewDidEnter(async  () => {
       
        if(searchBar.current) {
            setTimeout(() => {
                searchBar.current.setFocus()
            }, 50);
           
        }
    })

    const search = (e) => {

        if(e.target.value.length < 2){
            setResults(null)
            return;
        }

        let params = {};
        if(category) {
          params = { filters: 'category_id:' + category }
        }
        index.search(e.target.value, params)
        .then( ( response ) => {
          setResults(response.hits)
        } )
        .catch( ( error ) => {
          //
        } );
    }

    const redirect = (url) => {

        switch(url.split('?id=')[0]) {
            case '/doctor-details':
                dispatch(getDoctor(url.split('?id=')[1]))
                break;
            case '/domain':
                dispatch(getCategory(url.split('?id=')[1], 1));
                break
            default:
                break;
        }
       
       dispatch(closeSearchModal());
        router.push(url);
    }

  return (
    <IonPage className='search-page'>
    <IonHeader className="ion-no-border">

    <IonToolbar>
      
        <IonButtons slot="start" className="backIcon">
            <IonIcon onClick={close} icon={arrowForwardOutline}></IonIcon>
        </IonButtons>
        <div className="searchB">
            <IonSearchbar ref={searchBar} type="text" debounce="500" onKeyUp={search} autoFocus={true}
                placeholder="שם, טיפול, מומחיות, מרפאה…" searchIcon="search-sharp" enterkeyhint="search" inputmode="search">
            </IonSearchbar>
        </div>
    </IonToolbar>
</IonHeader>

<IonContent id="withBg">
    <IonRow>
        <IonCol>
            {!!results?.length && <div className="searchRes" >
                <IonList className="autoCompleteList" lines="full">
                    {results.filter((item) => item.type == "category").map((cat, key) => (
                    <IonItem key={key} onClick={() => redirect(cat.url)} lines="none">
                        <div className="itemTitle">
                            <div  dangerouslySetInnerHTML={{ __html: cat._highlightResult.name.value }}></div>
                            <div>
                                <IonIcon icon={libraryOutline}></IonIcon>
                            </div>
                        </div>

                    </IonItem>))}
                </IonList>
                <IonList className="autoCompleteList" lines="full">
                    {results.filter((item) => item.type == "professional").map((cat, key) => (
                    <IonItem key={key}  onClick={() => redirect(cat.url)}  lines="none">
                        <div className="itemTitle">
                            <div>
                            <div  dangerouslySetInnerHTML={{ __html: cat._highlightResult.name.value }}></div>
                       
                                <div className="cat">- {cat.category}</div>
                                {cat.city && <div className="cat" >- {cat.city}</div>}
                            </div>
                            <div>
                                <IonIcon icon={personOutline} />
                            </div>
                        </div>

                    </IonItem>))}
                </IonList>
            </div>}
            {results && !results.length && <div className=" noResults">
                אין נתונים <br/> נסה חיפוש אחר
            </div>}
        </IonCol>
    </IonRow>

    <br />
    {/*
    <div *ngIf="results && results.professionals && results.categories && results.expertises">
        <div *ngIf="!results.professionals.length && !results.categories.length && !results.expertises.length">
            אין נתונים <br/> נסה חיפוש אחר
        </div>

        <div *ngIf="results.categories.length">
            <IonRow>
                <IonCol>
                    <span>קטגוריות</span>
                </IonCol>
            </IonRow>
            <ion-grid *ngFor="let result of results.categories">
                <ion-card className="no-margin" routerLink="/domain" [queryParams]="{ type: result.id}">
                    <IonRow>
                        <IonCol>
                            <span> {{ result.name}}</span>
                        </IonCol>
                    </IonRow>
                </ion-card>
            </ion-grid>
        </div>

        <div *ngIf="results.expertises.length">
            <IonRow>
                <IonCol>
                    <span>התמחויות</span>
                </IonCol>
            </IonRow>
            <ion-grid *ngFor="let result of results.expertises">
                <ion-card className="no-margin">
                    <IonRow>
                        <IonCol>
                            <span> {{ result.name}} </span>
                        </IonCol>
                    </IonRow>
                </ion-card>
            </ion-grid>
        </div>

        <div *ngIf="results.professionals.length">
            <IonRow>
                <IonCol>
                    <span>רופאים</span>
                </IonCol>
            </IonRow>
            <ion-grid *ngFor="let result of results.professionals">
                <ion-card className="no-margin" routerLink="/doctor-details" [queryParams]="{ id: result.id}">
                    <IonRow>
                        <IonCol>
                            <span> {{ result.first_name}} {{ result.last_name }}</span>
                        </IonCol>
                    </IonRow>
                </ion-card>
            </ion-grid>
        </div>


                    </div>*/}
        </IonContent>
    </IonPage>
  );
};

export default SearchPage;

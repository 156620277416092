import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter, useIonViewDidEnter, isPlatform, IonRow, IonCol, IonSearchbar, IonGrid, IonText, IonCard, IonInfiniteScroll, IonInfiniteScrollContent, IonAvatar, IonIcon, IonModal, IonLoading   } from '@ionic/react';
import './Domain.css';
import { useDispatch, useSelector } from 'react-redux';
import { getCategory, getHome, getMeDetails, presentRecModal, saveDoctor, sendContacts, setAsAuthenticated } from '../redux/actions/authActions';
import { useLocation } from 'react-router';
import phone from "../assets/icon/phone.svg"
import locationSvg from "../assets/icon/location.svg";
import save_selected from "../assets/icon/save_selected.svg"
import save from '../assets/icon/save.svg'
import love from "../assets/icon/love.svg"
import love_selected from "../assets/icon/love_selected.svg"
import person from "../assets/icon/person.svg"
import Header from '../components/Header';
import Recommendation from './Recommendation';
import SearchPage from './SearchPage';

const Domain = () => {

  const router = useIonRouter()
  const dispatch = useDispatch();
  const location = useLocation();

  const perPage = 10;

  const id = (new URLSearchParams(location.search.replace('?', ''))).get('id');
  const { user, isAuthenticated, categoryData, doctors, rec_modal, search_modal } = useSelector((state = {}) => state.auth);
  const { loading, liveActions } = useSelector((state = {}) => state.async);

  const [page, setPage] = useState(1);

  useIonViewDidEnter(() => {
   
    if (!localStorage.getItem('AuthToken')) {
      router.push('/login')
    }

    if (!liveActions.includes('me') && !isAuthenticated && localStorage.getItem("AuthToken")) {
      dispatch(setAsAuthenticated(localStorage.getItem('AuthToken')))
      dispatch(getMeDetails())
    }

    if(id && (!categoryData || categoryData.id != id) && !liveActions.includes('domain')) {
      dispatch(getCategory(id, 1));
      setShowExpertises([])
      setSelectedExpertises([])
    }
  });

  useEffect(() => {
    if(categoryData && (!showExpertises.length || categoryData.expertises[0].id != showExpertises[0].id )){
    
      setShowExpertises(categoryData.expertises.splice(0, 4));
    }
  }, [categoryData])


  const [showExpertises, setShowExpertises] = useState([])
  const [selectedExpertises, setSelectedExpertises] = useState([]);
  const filterByExpertise = (id) => {
    let data = selectedExpertises.includes(id) 
    ? selectedExpertises.filter(e => e != id) 
    : [...selectedExpertises, id]
    setSelectedExpertises(data)
   
      dispatch(getCategory(categoryData.id, 1, data.join(',')))
  }

  const toDoctor = (id) => {
    router.push('/doctor-details?id=' + id)
  }

  const toogleLike = (e, doctor) => {
    e.stopPropagation();
 
    dispatch(presentRecModal({professional: {...doctor, category: categoryData}}));
  }

  const doInfinite = (ev) => {
    dispatch(getCategory(id, parseInt(doctors.length / 10 + 1)), showExpertises)
    setTimeout(() => ev.target.complete(), 500);
  }

  const ShowOrHideExpertise = () => {
    let isHide = categoryData.expertises.length === showExpertises.length;

    if (isHide) {
      setShowExpertises(categoryData.expertises.splice(0, 4));
    } else {
      setShowExpertises([...categoryData.expertises]);   
    }
  }

  const toogleSave = async (e, doctor) => {  
    e.stopPropagation();
    dispatch(saveDoctor(doctor.id, !doctor.isSaved, doctor.isSaved ? "מסירים לך את השמירה..." : "שומרים לך את הדוקטור..."))
  }

  if(location.pathname != '/domain') {
    return (<></>)
  }

  return (
    <IonPage className='domain-page'>
     <Header search={true}/>
      <IonContent fullscreen>
        {liveActions.includes('domain') && <IonLoading isOpen={loading && liveActions.includes('domain') && id != categoryData?.id}/>}
        {categoryData && id == categoryData.id &&
      <div>
        <IonRow style={{'marginTop': '-20px'}}>
            <IonCol>
              <IonText className="title ion-text-center">
                <h1> {categoryData.name}</h1>
              </IonText>
            </IonCol>
          </IonRow>
          <IonGrid>
            <IonRow className="ion-text-center">
              <IonCol>
                {showExpertises.map((expertise, key) => (
                <IonCard key={key} className={'exp-card ' +  (selectedExpertises.includes(expertise.id) ? 'selected' : '')} >
                  <span onClick={() => filterByExpertise(expertise.id)}>{expertise.name}</span>
                </IonCard>))}
                { categoryData.expertises.length > 4 &&
                <IonCard className={'exp-card'}>
                    <span onClick={ShowOrHideExpertise} >
                        { categoryData.expertises.length === showExpertises.length ? "- להסתיר" : "עוד..."}
                    </span>
                </IonCard>}

              </IonCol>
            </IonRow>

          </IonGrid>

          {doctors && 
          <div className="doctors">
            {doctors.map((doctor, key) => (
            <IonGrid key={key}>
              <IonCard onClick={() => { toDoctor(doctor.id)}}>
                <IonRow className="ion-justify-content-start">
                  <IonCol size="2">
                    <IonAvatar width="25px">
                      <img src={doctor.img_url ? doctor.img_url : person} />
                    </IonAvatar>
                  </IonCol>
                  <IonCol>
                    <span className="DrName">{doctor.first_name} {doctor.last_name}</span>
                    <span className="DrDomain">{categoryData.name}</span>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className="DrTags">
                        {doctor.expertises.map((expertise, key) => (
                      <span key={key}>
                        {expertise.name}
                      </span>))}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                        {(doctor.street || doctor.house_number || doctor.city) && 
                      <span  className="DrAddress">
                        <IonIcon src={locationSvg}></IonIcon>
                        {doctor.street} {doctor.house_number && <span> {doctor.house_number},</span>}  {doctor.city}
                      </span>}
                  
                     {doctor.phone &&
                      <span className="DrPhone">
                        <IonIcon src={phone}></IonIcon><a href={"tel:"+doctor.phone }> {doctor.phone}</a>
                 
                      </span>}
                      {doctor.mobile &&
                      <span className="DrPhone">
                        <IonIcon src={phone}></IonIcon><a href={"tel:"+ doctor.mobile }> {doctor.mobile}</a>
                     
                      </span>}
                    </IonCol>
                  <div onClick={(e) => toogleSave(e, doctor)}>
                      <img src={doctor.isSaved ? save_selected : save} className="imgSave" />
                  </div>
                </IonRow>

                <IonRow className="ion-align-items-center ion-justify-content-start ion-text-center">
                  <IonCol size="12" className="ion-text-right recomsNbr">
                    המלצות
                  </IonCol>
                  <IonCol size="2">
                    <IonText>
                      <p>
                        <span className="recomNum">{ doctor.recCount.friends }<br /></span>
                        <span className="recomType"> חברים </span>
                      </p>
                    </IonText>
                  </IonCol>
                  <IonCol size="2">
                    <IonText>
                      <p>
                        <span className="recomNum">{ doctor.recCount.colleagues }<br /></span>
                        <span className="recomType"> קולגות </span>
                      </p>
                    </IonText>
                  </IonCol>
                  <IonCol size="2">
                    <IonText>
                      <p>
                        <span className="recomNum">{ doctor.recCount.likeMe  }<br /></span>
                        <span className="recomType"> כמוני
                        </span>
                      </p>
                    </IonText>
                  </IonCol>
                  <IonCol size="2">
                    <IonText>
                      <p>
                        <span className="recomNum">{doctor.recCount.all }<br /></span>
                        <span className="recomType"> סך הכל
                        </span>
                      </p>
                    </IonText>
                  </IonCol>
                  <IonCol className="ion-text-left">
                    <div className="imgHeart" onClick={(e) => toogleLike(e, doctor)}>
                      <span className="helper"></span>
                      <img src={doctor.isLike ? love_selected : love}/>
                    </div>

                  </IonCol>
                </IonRow>

              </IonCard>
            </IonGrid>))}

            <IonInfiniteScroll onIonInfinite={doInfinite} /**ngIf="hasMore()" (ionInfinite)="doInfinite($event)"*/ threshold="100px">
              <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="">
              </IonInfiniteScrollContent>
            </IonInfiniteScroll>

          </div>}
          </div>}
        </IonContent>
        {rec_modal && <IonModal isOpen={!!rec_modal}>
            <Recommendation />
        </IonModal>}
    { search_modal && <IonModal isOpen={!!search_modal}>
        <SearchPage />
    </IonModal>}
    </IonPage>
  );
};

export default Domain;

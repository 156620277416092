import axios from 'axios'
import {asyncActionStart, asyncActionFinish, asyncActionError} from './asyncActions'
import setAuthToken from '../../auth/setAuthToken'
import {
  LOGIN_FAIL,
  SET_AS_AUTHENTICATED,
  LOGOUT,
  USER_DATA,
  OTP,
  RESTART_OTP,
  SETTINGS,
  HOME_DATA,
  CATEGORY_DATA,
  DOCTOR_DATA,
  OPEN_REC_MODAL,
  CLOSE_REC_MODAL,
  OPEN_SEARCH_MODAL,
  cLOSE_SEARCH_MODAL,
  REMOVE_REC,
  ADD_REC,
  THANK_REC_MODAL,
  SAVE_PROF,
  SAVE_PROF_LOADER
} from './types'

import { API_URL } from './api';


export const getSettings = () => async dispatch => {
  dispatch(asyncActionStart())

  try {
    const resSettings = await axios.get(`${API_URL}auth/settings/`)
    dispatch({
      type: SETTINGS,
      payload: resSettings.data?.data
    })
    dispatch(asyncActionFinish())
  } catch (e) {
    dispatch(asyncActionFinish())
  }
}
// Login User
// export const sendOtp=(data)=> 
export const sendOtp = (formData) => async dispatch => {
  dispatch(asyncActionStart())

  try {
    const resSettings = await axios.get(`${API_URL}auth/settings/`)
    dispatch({
      type: SETTINGS,
      payload: resSettings.data?.data
    })
    
    const url = `${API_URL}auth/login/`
    setTimeout(() => {
      dispatch({
        type: OTP,
      })
      dispatch(asyncActionFinish())
    }, 100);
    const res = await axios.post(url, formData)
    if(res.data.error) {
      dispatch({
        type: RESTART_OTP
        })
       
        dispatch(asyncActionError(res.data.error))
    }
    else {
          dispatch(asyncActionFinish())
    }
   
  } catch (error) {
    console.log(error)
    dispatch({
    type: RESTART_OTP
    })
    if (error.response && error.response.data && error.response.data.message) {
      
      dispatch(asyncActionError(error.response.data.message))
    } else {
      dispatch(asyncActionError('Server error'))
    }
  }
  
}

export const login = (formData) => async dispatch => {
  dispatch(asyncActionStart())
  try {
    const url = `${API_URL}auth/verify-code`

    const res = await axios.post(url, formData)
    if(res.data.data?.error) {
        dispatch(asyncActionError(res.data.data.error))
    }
    else if(res.data.data?.success){
      
        dispatch({
            type: USER_DATA,
            payload: res.data.data?.user_detail
          })
          dispatch(setAsAuthenticated(res.data.data.access_token))
          dispatch(asyncActionFinish());
       
    } else {
      
      dispatch({
        type: LOGIN_FAIL
      })

        
    }
    dispatch(asyncActionFinish())
   
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL
    })
    if (error.response && error.response.data && error.response.data.message) {
      
      dispatch(asyncActionError(error.response.data.message))
    } 
    else {
      dispatch(asyncActionError('Server error'))
    }
  }
  
}

export const sendContacts = (contacts) => async dispatch => {
  dispatch(asyncActionStart())
  try {
    const url = `${API_URL}member/contacts`

    const res = await axios.post(url, {contacts: contacts})
    if(res.data.data?.error) {
        dispatch(asyncActionError(res.data.data.error))
    }    
    dispatch(asyncActionFinish())
   
  } catch (error) {  
    if (error.response && error.response.data && error.response.data.message) {      
      dispatch(asyncActionError(error.response.data.message))
    } 
    else {
      dispatch(asyncActionError('Server error'))
    }
  }
}

//  return this.http.get(`${environment.baseUrl}/v1/home?page=${page}`);

export const getHome = (page) => async dispatch => {
  dispatch(asyncActionStart('home'))
  try {
    const url = `${API_URL}home?page=${page}`

    const res = await axios.get(url)
    if(res.data.data?.error) {
        dispatch(asyncActionError('home'))
    } else {
      dispatch({
        type: HOME_DATA,
        payload: res.data
      })
    }   
    dispatch(asyncActionFinish('home'))
   
  } catch (error) {     
      dispatch(asyncActionError('home'))
    
  }
}


export const getCategory = (id, page, selectedExpertises = '') => async dispatch => {
  dispatch(asyncActionStart('domain'))
  try {
    const url = `${API_URL}category/${id}?page=${page}&expertises=${selectedExpertises}
    `
    const res = await axios.get(url)
    if(res.data.data?.error) {
        dispatch(asyncActionError(res.data.data.error))
    } else {
      dispatch({
        type: CATEGORY_DATA,
        payload: {...res.data, add : page > 1}
      })
    }   
    dispatch(asyncActionFinish('domain'))
   
  } catch (error) {  
    if (error.response && error.response.data && error.response.data.message) {      
      dispatch(asyncActionError(error.response.data.message))
    } 
    else {
      dispatch(asyncActionError('domain'))
    }
  }
}

export const sendRec = (params) => async dispatch => {
  
  dispatch(asyncActionStart('recommendation'))
  try {
    const url = `${API_URL}member/recommendation`
    const res = await axios.post(url, params)
    if(res.data.data?.error) {
        dispatch(asyncActionError(res.data.data.error))
    } else {
      dispatch(closeRecModal())
      dispatch({
        type: ADD_REC,
        payload: res.data
      }) 
      
      setTimeout(() => {
        dispatch(ThankRecModal(false))
      }, 1500);
    }   
  
    dispatch(asyncActionFinish('recommendation'))
   
  } catch (error) {  
    if (error.response && error.response.data && error.response.data.message) {      
      dispatch(asyncActionError(error.response.data.message))
    } 
    else {
      dispatch(asyncActionError('Server error'))
    }
  }
}


export const clearDoctor = () => async dispatch => {
  dispatch({
    type: DOCTOR_DATA,
    payload: {data: null}
  })
}

export const getDoctor = (id) => async dispatch => {
  dispatch(asyncActionStart('doctor'))
  try {
    const url = `${API_URL}professional/${id}`

    const res = await axios.get(url)
    if(res.data.data?.error) {
        dispatch(asyncActionError(res.data.data.error))
    } else {
      dispatch({
        type: DOCTOR_DATA,
        payload: res.data
      })
    }   
    dispatch(asyncActionFinish('doctor'))
   
  } catch (error) {  
    if (error.response && error.response.data && error.response.data.message) {      
      dispatch(asyncActionError('doctor'))
    } 
    else {
      dispatch(asyncActionError('doctor'))
    }
  }
}


export const setAsAuthenticated = authToken => {
  localStorage.setItem("AuthToken", authToken);
  setAuthToken(authToken)
  return {type: SET_AS_AUTHENTICATED, payload: authToken}
}



export const getMeDetails = () => async dispatch => {
  dispatch(asyncActionStart('me'))
  try {
  const res = await axios.get(`${API_URL}member/profile`, )
    if(res.data.error) {
        dispatch(asyncActionError(res.data.error))
    }
    else {
        dispatch({
            type: USER_DATA,
            payload: res.data.data.user_detail
          })
    }
    dispatch(asyncActionFinish('me'))
   
  } catch (error) {    
  
    if (error.response && error.response.data && error.response.data.title) {
      // dispatch(asyncActionError(error.response.data.title))
      dispatch(asyncActionError(error.response.data))
    } else {
      if(error.response.status == 401) {
          dispatch({
            type: LOGOUT,
        })
        dispatch(asyncActionError(''))
      } 
    }
  }
}


export const saveProfile = (formData) => async dispatch => {
  dispatch(asyncActionStart('me'))
  try {
  const res = await axios.post(`${API_URL}member/profile`, {params : formData})
    if(res.data.error) {
        dispatch(asyncActionError(res.data.error))
    }
    else {
        dispatch({
            type: USER_DATA,
            payload: res.data.data.user_detail
          })
    }
    dispatch(asyncActionFinish('me'))
   
  } catch (error) {    
  
    if (error.response && error.response.data && error.response.data.title) {
      // dispatch(asyncActionError(error.response.data.title))
      dispatch(asyncActionError(error.response.data))
    } else {
      if(error.response.status == 401) {
          dispatch({
            type: LOGOUT,
        })
        dispatch(asyncActionError(''))
      } 
    }
  }
}

export const removeRecommendation = (id, professional_id = null) => async dispatch => {
  dispatch(asyncActionStart())
  try {
    const url = `${API_URL}member/remove-recommendation`

    const res = await axios.post(url, {id: id});
    if(res.data.data?.error) {
        dispatch(asyncActionError(res.data.data.error))
    } else {
      dispatch(closeRecModal())
      dispatch({
        type: REMOVE_REC,
        payload: {id: id, professional_id: professional_id}
      })      
    }   
 
    dispatch(asyncActionFinish())
   
  } catch (error) {  
    if (error.response && error.response.data && error.response.data.message) {      
      dispatch(asyncActionError(error.response.data.message))
    } 
    else {
      dispatch(asyncActionError('Server error'))
    }
  }
}


export const saveDoctor = (id, isSave, text) => async dispatch => {
  dispatch(asyncActionStart())
  dispatch({
    type: SAVE_PROF_LOADER,
    payload: text
  })
  
  try {
    const url = `${API_URL}member/save-professional`

    const res = await axios.post(url, {
      professional_id: id,
      is_save: isSave
    });
    if(res.data.data?.error) {
        dispatch(asyncActionError(res.data.data.error))
    } else {
      dispatch({
        type: SAVE_PROF,
        payload: {id}
      })      
    }   
 
    dispatch(asyncActionFinish())
   
  } catch (error) {  
    if (error.response && error.response.data && error.response.data.message) {      
      dispatch(asyncActionError(error.response.data.message))
    } 
    else {
      dispatch(asyncActionError('Server error'))
    }
  }
}

export const presentRecModal = (doctor_data) =>{
  return{
    type: OPEN_REC_MODAL,
    payload: doctor_data
  }
}

export const ThankRecModal = (text) => {
  return {
    type: THANK_REC_MODAL,
    payload: text
  }
}


export const closeRecModal = () =>{
  return{
    type: CLOSE_REC_MODAL,
  }
}


export const presentSearchModal = () =>{
  return{
    type: OPEN_SEARCH_MODAL,
  }
}


export const closeSearchModal = () =>{
  return{
    type: cLOSE_SEARCH_MODAL,
  }
}

export const restartOtp=()=>{
  return{
    type: RESTART_OTP,
  }
}
// Logout
export const logout = () => {
  setAuthToken(false)
  return {
    type: LOGOUT
  }
}

